<template>
    <div>

        <el-form-item label="短信平台">
            <el-radio v-model="smsDefault" :label="item.key" :key="item.key" v-for="item in smsLabels">{{ item.name }}</el-radio>
        </el-form-item>

        <template v-if="smsDefault === 'aliyun'">
            <el-form-item label="SdkAppId">
                <el-input size="small" placeholder="" v-model="formData.values.engine.aliyun.SdkAppId"></el-input>
            </el-form-item>
            <el-form-item label="accessKey">
                <el-input size="small" placeholder="" v-model="formData.values.engine.aliyun.access_key"></el-input>
            </el-form-item>
            <el-form-item label="secretKey">
                <el-input size="small" placeholder="" v-model="formData.values.engine.aliyun.secret_key"></el-input>
            </el-form-item>
            <el-form-item label="短信签名Sign">
                <el-input size="small" placeholder="" v-model="formData.values.engine.aliyun.sign"></el-input>
            </el-form-item>
        </template>

        <submit-btn @settingConfigData="settingConfigData"></submit-btn>
    </div>
</template>

<script>
import submitBtn from "@/views/systemManage/config/components/submitBtn.vue";
import {getSettingConfigApi, settingConfigApi} from "@/views/systemManage/config/api";

export default {
    name: "smsConfig",
    components: {submitBtn},

    data() {
        return {
            smsDefault: 'aliyun',

            smsLabels: [
                {name: '阿里云短信', key: 'aliyun'},
                // {name: '腾讯云', key: 'qiniu'},
                // {name: '阿里云', key: 'aliyun'},
            ],

            formData: {
                key: 'sms',
                describe: '短信设置',
                values: {
                    default: 'aliyun',
                    engine: {
                        aliyun: {}
                    }
                }
            },
        }
    },

    mounted() {
        getSettingConfigApi(this.formData.key).then(res => {
            if (res.values) {
                this.formData.values = res.values || {}
            }

            this.smsDefault = res.values.default || 'aliyun'
        })
    },

    methods: {
        settingConfigData() {
            settingConfigApi(this.formData).then(() => {
                this.$message.success('更新成功')
            })
        },
    }
}
</script>

<style scoped>

</style>
