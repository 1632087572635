<template>
  <div>
    <el-form-item label="热线号码" label-width="80px">
      <el-input style="width: 400px" size="small" v-model="formData.values.contact" placeholder=""></el-input>
    </el-form-item>
    <submit-btn @settingConfigData="settingConfigData"></submit-btn>
  </div>
</template>

<script>
import submitBtn from './submitBtn.vue'
import { settingConfigApi, getSettingConfigApi } from '../api'
import SubmitBtn from '@/views/systemManage/config/components/submitBtn.vue'

export default {
  name: 'contractConfig',
  components: { SubmitBtn },

  data() {
    return {
      formData: {
        key: 'contact',
        describe: '联系热线',
        values: {
          contact: ''
        }
      }
    }
  },

  mounted() {
    getSettingConfigApi(this.formData.key).then(res => {
      this.formData.values = res.values || {}
    })
  },

  methods: {
    settingConfigData() {
      settingConfigApi(this.formData).then(() => {
        this.$message.success('更新成功')
      })
    }
  }
}
</script>

<style scoped></style>
