import { http } from '@/http/axios.js'

export function settingConfigApi(data) {
  return http({
    url: '/company/basic/setting/add',
    method: 'POST',
    data
  })
}

export function getSettingConfigApi(key) {
  return http({
    url: '/company/basic/setting/detail',
    method: 'get',
    params: { key }
  })
}


