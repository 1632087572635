import { render, staticRenderFns } from "./expressConfig.vue?vue&type=template&id=9220ab78&scoped=true&"
import script from "./expressConfig.vue?vue&type=script&lang=js&"
export * from "./expressConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9220ab78",
  null
  
)

export default component.exports