<template>
  <el-form ref="form" :model="formData" label-width="150px">
    <el-form-item label="待指派案件超期时间">
      <el-input-number
        style="width: 200px; margin-right: 20px"
        v-model="formData.values.designate_time_out"
        :min="1"
        :step="1"
        step-strictly
        size="small"
      ></el-input-number>
      天
    </el-form-item>
    <el-form-item label="待受理案件超期时间">
      <el-input-number
        style="width: 200px; margin-right: 20px"
        v-model="formData.values.accept_time_out"
        :min="1"
        :step="1"
        step-strictly
        size="small"
      ></el-input-number>
      天
    </el-form-item>
    <el-form-item label="">
      <el-button type="primary" size="small" @click="save">保存</el-button>
      <el-button type="" size="small" @click="getSettingConfig">取消</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { getSettingConfigApi, settingConfigApi } from '../api'
export default {
  name: 'Timeout',

  data() {
    return {
      formData: {
        key: 'case_time_out',
        describe: '超时时间',
        values: {
          designate_time_out: '', // 指派超时
          accept_time_out: '' // 受理超时
        }
      }
    }
  },

  mounted() {
    this.getSettingConfig()
  },

  methods: {
    save() {
      settingConfigApi(this.formData).then(() => {
        this.$message.success('设置成功')
        this.getSettingConfig()
      })
    },
    async getSettingConfig() {
      this.formData = await getSettingConfigApi('case_time_out')
    }
  }
}
</script>

<style lang="scss" scoped></style>
