<template>
  <el-form-item label="" label-width="0px">
    <el-button  v-checkbtn="isShowBtn(AUTH_BTN.sys_list_edit)" type="primary" size="small" @click="submit">提交</el-button>
    <el-button type="" size="small">取消</el-button>
  </el-form-item>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
export default {
  name: 'submitBtn',
  mixins: [authBtnMixin],
  methods: {
    submit() {
      this.$emit('settingConfigData')
    }
  }
}
</script>

<style scoped></style>
